@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nexa";
  src: local("Nexa"),
    url("./assets/fonts/Nexa/Nexa-Trial-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}